'use client';

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import { NotFoundView } from 'src/sections/error';

export default function GlobalError({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return <NotFoundView />;
}
